import { useState, useEffect } from "react";
import Post from "./Post";
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css/animate.min.css";

const Works = () => {
  const restPath =
    "https://brandonbirk.ca/portfolio-backend/wp-json/wp/v2/brandonbirk-works?_embed&acf_format=standard";
  const [restData, setData] = useState([]);
  const [isLoaded, setLoadStatus] = useState(false);
  const [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(restPath);
        if (response.ok) {
          const data = await response.json();
          // Sort the posts by creation date in descending order
          const sortedData = data.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setData(sortedData);
          setLoadStatus(true);
        } else {
          setLoadStatus(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadStatus(false);
      }
    };
    fetchData();
  }, [restPath]);

  // Update the image path when needed
  useEffect(() => {
    if (restData.length > 0 && restData[0].acf.cover) {
      const newImagePath = restData[0].acf.cover.url;
      setImagePath(newImagePath);
    }
  }, [restData]);

  // Initialize the animate-on-scroll
  AOS.init({
    duration: 1000, // Set the animation duration
    once: true, // Only animate once per viewport on page load
  });

  return (
    <>
      {isLoaded && restData.length > 0 ? (
        <article className="work-container">
          <h2 className="work-section-title">Work</h2>

          {restData.map((post) => (
            <Post key={post.id} post={post} imagePath={imagePath} />
          ))}
        </article>
      ) : (
        <h2>Works Loading...</h2>
      )}
    </>
  );
};

export default Works;
