import React from "react";

const Logo = () => {
  return (
    <div className="logo-name">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 576">
        <g>
          <g>
            <path
              d="M443.61,286.49c-26.41-7.18-38.96-26.39-38.96-64.8c0-16.2,2.39-30.62,4.81-45.62c1.78-14.42,4.2-28.81,4.2-42.61
			c0-32.4-229.16-51.02-263.98-51.6h-10.8V48.84h12c51.02,0,304.17,23.41,304.17,78.6c0,16.81-2.39,33.01-4.79,49.82
			c-2.39,16.81-4.81,33.62-4.81,50.41c0,18.42,4.62,38.67,34.45,43.4c8.12,1.29,14.18,8.16,14.18,16.38v0
			c0,8.14-5.94,14.99-13.97,16.34c-30.01,5.08-34.65,26.98-34.65,43.31c0,16.2,2.42,32.42,4.81,48.62
			c2.39,16.2,4.79,33.01,4.79,49.21c0,60.62-254.35,82.22-303.56,82.22h-12.61v-33.01h10.21c33.6-0.59,264.56-18.59,264.56-52.22
			c0-14.39-2.42-28.2-4.2-43.2c-0.94-5.58-2-16.68-2.95-29.11C404.04,337.42,417.97,306.13,443.61,286.49L443.61,286.49z"
            />
          </g>
          <g>
            <path
              d="M333.52,384.34l-89.2,57.7v-26.47l37.6-39.56v-0.4l-37.6-39.56V322.1l88.35,45.32c3.27,1.67,5.32,5.04,5.32,8.71v0
			C337.99,379.44,336.3,382.54,333.52,384.34z"
            />
          </g>
          <g>
            <path
              d="M314.43,200.25l-70.11,45.35V224.8l29.55-31.1v-0.32l-29.55-31.1v-10.95l69.45,35.62c2.57,1.32,4.18,3.96,4.18,6.84v0
			C317.94,196.4,316.62,198.83,314.43,200.25z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
export default Logo;
