import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ScrollButton from "./ScrollButton";
import Footer from "./Footer";

const PostPage = () => {
  const { slug } = useParams();
  const restPath = `https://brandonbirk.ca/portfolio-backend/wp-json/wp/v2/brandonbirk-works?slug=${slug}&_embed&acf_format=standard`;
  const [restData, setData] = useState({});
  const [isLoaded, setLoadStatus] = useState(false);
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(restPath);
        if (response.ok) {
          const data = await response.json();
          setData(data[0]);
          setLoadStatus(true);

          // Fetch related posts
          const relatedPostsResponse = await fetch(
            `https://brandonbirk.ca/portfolio-backend/wp-json/wp/v2/brandonbirk-works?per_page=3&_embed&exclude=${data[0].id}`
          );
          if (relatedPostsResponse.ok) {
            const relatedPostsData = await relatedPostsResponse.json();
            setRelatedPosts(relatedPostsData);
          }
        } else {
          setLoadStatus(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [restPath]);

  return (
    <>
      {isLoaded ? (
        <>
          <article className="work-page-container" id={`post-${restData.id}`}>
            <section id="overview">
              <video
                className="work-page-cover"
                loop
                muted
                playsInline
                autoPlay
              >
                <source src={restData.acf.image_1.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <h1 className="work-page-title">{restData.acf.title}</h1>
              <span className="tagline">{restData.acf.tagline}</span>
              <h2 className="work-page-brief">{restData.acf.overview}</h2>

              <div className="work-links-container">
                {restData.acf.link_1?.url && (
                  <p className="work-link">
                    <a
                      href={restData.acf.link_1.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {restData.acf.link_1.title}
                    </a>
                  </p>
                )}
                {restData.acf.link_2?.url && (
                  <p className="work-link">
                    <a
                      href={restData.acf.link_2.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {restData.acf.link_2.title}
                    </a>
                  </p>
                )}
              </div>

              <div className="work-details-container">
                <div className="item">
                  <h3>Client</h3>
                  <p>{restData.acf.client}</p>
                </div>
                <div className="item">
                  <h3>Skills</h3>
                  <p>{restData.acf.qualities}</p>
                </div>
              </div>
            </section>

            <section id="main-content">
              {/* Render the WordPress block editor content */}
              <div
                className="work-content"
                dangerouslySetInnerHTML={{
                  __html: restData.content?.rendered,
                }}
              ></div>

              <ScrollButton />
            </section>

            {/* Related Posts */}
            {relatedPosts.length > 0 && (
              <section id="related-posts">
                <h3>Other Work</h3>
                <ul>
                  {relatedPosts.map((post) => (
                    <li key={post.id}>
                      <a href={`/works/${post.slug}`}>{post.title.rendered}</a>
                    </li>
                  ))}
                </ul>
              </section>
            )}
          </article>
        </>
      ) : (
        <h2>Loading...</h2>
      )}
    </>
  );
};

export default PostPage;
